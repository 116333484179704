import React, { useContext, useState } from "react";
import { Link } from "gatsby";

import { CurrentUserContext } from "../providers/auth";

import Layout from "../components/layout";
import Seo from "../components/seo";

const ResetPasswordPage = () => {
    const [success, setSuccess] = useState(false);
    return (
        <Layout>
            <Seo title="Reset Password" />
            <div className="flex flex-col md:justify-center items-center p-4 w-full h-screen -mt-16 pt-16">
                <div className="absolute inset-0 mesh-bg -z-10" />
                {success ? (
                    <div className="flex flex-col rounded w-full md:w-1/2 lg:w-1/3 bg-lightgreen1 px-4 py-6">
                        <div className="flex flex-row items-center">
                            <span>
                                Your new password is set. Go to
                                <Link to="/signin" className="mx-1 underline">
                                    Sign in
                                </Link>
                            </span>
                        </div>
                    </div>
                ) : (
                    <RequestResetPasswordBox
                        onSucccess={() => {
                            setSuccess(true);
                        }}
                    />
                )}
            </div>
        </Layout>
    );
};

export default ResetPasswordPage;

// local components

const RequestResetPasswordBox = ({ onSucccess }) => {
    const currentUser = useContext(CurrentUserContext);

    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [clicked, setClicked] = useState(false);
    const [inputEmail, setInputEmail] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [inputPassword, setInputPassword] = useState(false);
    const [inputCode, setInputCode] = useState(false);
    const [error, setError] = useState(null);
    return (
        <div className="flex flex-col rounded w-full md:w-1/2 lg:w-1/3 bg-lightgreen1 px-4 py-6">
            <h1 className="text-lg mb-2">Forgot Password</h1>
            {emailValid ? (
                <>
                    <input
                        className="rounded-full px-4 py-2 text-xs mt-4 mb-2"
                        type="text"
                        placeholder="Verification code in the email we sent you."
                        value={code}
                        onChange={(e) => {
                            setCode(e.target.value);
                            setInputCode(true);
                            setError(null);
                        }}
                    />
                    {clicked && !code ? (
                        <div className="flex flex-row justify-center text-red text-xs">
                            Verification code is required
                        </div>
                    ) : null}
                    {inputCode && code.indexOf(" ") >= 0 ? (
                        <div className="flex flex-row justify-center text-red text-xs">
                            Verification code shall not contain space
                        </div>
                    ) : null}
                    <input
                        className="rounded-full px-4 py-2 text-xs mt-4 mb-2"
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                            setInputPassword(true);
                            setError(null);
                        }}
                    />
                    <div className="flex flex-row text-gray-400 text-xs px-4">
                        Password shall be minimum 8 characters including
                        uppercase letter, lowercase letter and number
                    </div>
                    {inputPassword && !newPassword ? (
                        <div class="flex flex-row justify-center text-red text-xs">
                            Password is required
                        </div>
                    ) : null}
                    <input
                        className="rounded-full px-4 py-2 text-xs mt-4 mb-2"
                        type="password"
                        placeholder="Confirm New Password"
                        value={confirmNewPassword}
                        onChange={(e) => {
                            setConfirmNewPassword(e.target.value);
                            setInputPassword(true);
                            setError(null);
                        }}
                    />
                    {inputPassword && newPassword !== confirmNewPassword ? (
                        <div class="flex flex-row justify-center text-red text-xs">
                            Password and Re-enter Password are not matched
                        </div>
                    ) : null}
                    <button
                        className="primary btn my-4"
                        onClick={() => {
                            setClicked(true);
                            if (
                                code &&
                                code.indexOf(" ") < 0 &&
                                newPassword &&
                                newPassword === confirmNewPassword
                            ) {
                                currentUser
                                    .resetPassword(email, code, newPassword)
                                    .then(() => {
                                        setError(null);
                                        onSucccess();
                                    })
                                    .catch((err) => {
                                        setError(err);
                                    })
                                    .finally(() => {
                                        setClicked(false);
                                    });
                            }
                        }}
                    >
                        Set password
                    </button>
                </>
            ) : (
                <>
                    <input
                        className="rounded-full px-4 py-2 text-xs mt-4 mb-2"
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setInputEmail(true);
                            setError(null);
                        }}
                    />
                    {clicked && !email ? (
                        <div className="flex flex-row justify-center text-red text-xs">
                            Email is required
                        </div>
                    ) : null}
                    {inputEmail && email.indexOf(" ") >= 0 ? (
                        <div className="flex flex-row justify-center text-red text-xs">
                            Email shall not contain space
                        </div>
                    ) : null}
                    <button
                        className="primary btn my-4"
                        onClick={() => {
                            setClicked(true);
                            if (email && email.indexOf(" ") < 0) {
                                currentUser
                                    .forgotPassword(email)
                                    .then(() => {
                                        setEmailValid(true);
                                        setError(null);
                                    })
                                    .catch((err) => {
                                        setEmailValid(false);
                                        setError(err);
                                    })
                                    .finally(() => {
                                        setClicked(false);
                                    });
                            }
                        }}
                    >
                        Reset password
                    </button>
                </>
            )}
            {error ? (
                <div className="flex flex-row justify-center items-center h-1 py-2 text-red text-sm">
                    {error.message}
                </div>
            ) : null}
        </div>
    );
};
